import React, { Component, Fragment } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterTwo";
import Testimonial from "../elements/Testimonial";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import TeamOne from "../blocks/team/TeamOne";
import Slider from "react-slick";
import { IoLogoWhatsapp } from "react-icons/io";
import { slickDot, portfolioSlick2 } from "../page-demo/script";
import { FaWpforms, FaUserNurse } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "需要即時醫療人手？",
    description:
      "我們為有需要的單位提供醫療中介服務，為季節性人手流失作出迅速和可靠的醫療人員空缺填補。",
    buttonText: "聯絡我們",
    buttonLink: "https://wa.me/85261613218?text=你好,我想查詢有關服務",
  },
];
const ServiceListOne = [
  {
    icon: "icon-01.png",
    title: "服務對象",
    description: [
      "公/私營醫院",
      "診所",
      "政府資助機構",
      "社福機構",
      "安老院",
      "各大機構/學校",
    ],
  },
  {
    icon: "icon-02.png",
    title: "提供人員",
    description: [
      "註冊護士",
      "登記護士",
      "物理治療師",
      "職業治療師",
      "言語治療師",
      "營養師",
    ],
  },
];
class CaregiverServices extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="CareGiver Service" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-activation slider-creative-agency" id="home">
          <div className="bg_image bg_image--4" data-black-overlay="4">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-primary-color"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                              <IoLogoWhatsapp size={20} />
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="text-center">
                    <div className="service service__style--equalHealth">
                      <h2 className="title">{val.title}</h2>
                      {val.description.map((descriptions, index) => (
                        <div className="row">
                        
                        <div  key={index} className="left_box col-xl-5 col-lg-5 col-md-4 col-sm-4 col-4">
                          <p className="text-right">•</p>
                        </div>
                        <div  key={index} className="right_box col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <p className="text-left"> {descriptions}</p>
                        </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default CaregiverServices;
