import React, { Component, Fragment, createRef } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaWpforms, FaUserNurse } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { Container, Row, Col } from "react-bootstrap";
const PortfolioList = [
  {
    images: "護理員",
    title: "護理員",
    require: "須持有以下至少一項本地認可資格 :",
    jobTitle: ["護理員", "陪診員", "起居護理員", "長者照顧員", "長者護理員"],
    job: ["個人護理", "護送病人", "陪伴護理", "陪診", "協助日常活動"],
  },
  {
    images: "保健員",
    title: "保健員",
    require: "須持有以下至少一項本地認可資格 :",
    jobTitle: [
      "保健員",
      "離院病人居家護理員",
      "高級保健員",
      "抽血員",
      "醫療支援人員",
      "物理治療助理",
      "健康服務助理",
      "職業治療助理",
      "康復助理",
      "陪月",
    ],
    job: [
      "個人護理",
      "導管餵食",
      "陪伴護理",
      "監察身體狀態",
      "協助日常活動",
      "緊急支援",
      "藥物管理",
    ],
  },
  {
    images: "登記護士",
    title: "登記護士",
    require: "須完成一個為期至少2年的護理文憑課程。",
    job: [
      "個人護理",
      "急救",
      "監察身體狀態",
      "提供緊急支援",
      "藥物管理",
      "感染控制",
      "疾病管理",
      "提供營養建議",
      "進行健康評估",
      "提供健康評估",
      "提供家居改裝建議",
      "撰寫護理報告",
    ],
  },
  {
    images: "註冊護士",
    title: "註冊護士",
    require: "需完成一個為期至少4年的護理學士課程。",
    job: [
      "個人護理",
      "急救",
      "監察身體狀態",
      "提供緊急支援",
      "藥物管理",
      "感染控制",
      "疾病管理",
      "提供營養建議",
      "進行健康評估",
      "提供健康評估",
      "提供家居改裝建議",
      "撰寫護理報告",
    ],
  },
  {
    images: "專職醫護人員",
    title: "專職醫護人員",
    require: "須持有以下至少一項本地認可資格 :",
    jobTitle: ["物理治療師", "職業治療師", "言語治療師", "營養師"],
    job: [
      "針對客戶需要和病情，為客戶制定治療計劃",
      "為客戶和家屬提供護理指導，以便調適生活",
    ],
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.itemRefs = PortfolioList.map(() => createRef());
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    const scrollToSection = (index) => {
      const itemRef = this.itemRefs[index].current;
      if (itemRef) {
        window.scrollTo({
          top: itemRef.offsetTop,
          behavior: "smooth",
        });
      }
    };
    return (
      <Fragment>
        <Helmet pageTitle="Home" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="creative-portfolio-wrapper bg_color--1">
          <div className="creative-portfolio-wrapper plr--10">
            <div className="row row--5 justify-content-center">
              {PortfolioList.map((value, i) => (
                <div className="col-lg-2 col-md-4 col-sm-6 col-12" key={i}>
                  <div className="portfolio-style--3">
                    <div className="thumbnail">
                      <div
                        onClick={() => {
                          scrollToSection(i);
                        }}
                      >
                        <img
                          className="w-100"
                          src={`/assets/images/equalHealthPhoto/${value.images}.jpg`}
                          alt="Portfolio Images"
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a
                          onClick={() => {
                            scrollToSection(i);
                          }}
                        >
                          {value.title}
                        </a>
                      </h4>
                      <div className="portfolio-btn">
                        <button
                          className="rn-btn text-white"
                          onClick={() => {
                            scrollToSection(i);
                          }}
                        >
                          了解更多
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Slider Area   */}

        {PortfolioList.map((value, i) => (
          <div key={i + "frame"} className="frame-wrapper">
            {value.jobTitle && (
              <div
                key={i + "detail"}
                className={`rn-featured-service-area pt--30 pb--50 ${
                  i % 2 === 0 ? "bg_color--5" : "bg_color--1"
                }`}
              >
                <div className="container">
                  {" "}
                  {/* Add the text-center class here */}
                  <h2 ref={this.itemRefs[i]} className="HiringTitle">
                    {value.title}
                  </h2>
                  <div className="row mt--50">
                    {/* Start Single Service Title */}
                    <div className="col-lg-4 col-md-4 col-12">
                      <img
                        className="HiringPhoto"
                        src={`/assets/images/equalHealthPhoto/${value.images}.jpg`}
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mt--30">
                      <div className="hiring-section-title">
                        <h3>申請者入職要求：</h3>
                        <h4>{value.require}</h4>

                        {value.jobTitle &&
                          value.jobTitle.map((jobTitle, index) => (
                            <ul key={index + jobTitle}>
                              <li>{jobTitle}</li>
                            </ul>
                          ))}
                      </div>
                    </div>
                    {/* End Single Service title */}
                    {/* • */}
                    {/* Start Single Service */}
                    <div className="col-lg-4 col-md-4 col-12 mt--30">
                      <div className="row">
                        <div className="col">
                          <div className="hiring-section-title">
                            <h3>工作範圍：</h3>
                          </div>
                          {value.job &&
                            value.job.map((job, index) => (
                              <ul key={index + job}>
                                <li>{job}</li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* End Single Service */}
                  </div>
                </div>
              </div>
            )}
            {!value.jobTitle && (
              <div
                key={i + "detail"}
                className={`rn-featured-service-area pt--30 pb--50 ${
                  i % 2 === 0 ? "bg_color--5" : "bg_color--1"
                }`}
              >
                <div className="container">
                  {" "}
                  {/* Add the text-center class here */}
                  <h2 ref={this.itemRefs[i]} className="HiringTitle">
                    {value.title}
                  </h2>
                  <div className="row mt--50">
                    {/* Start Single Service Title */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <img
                        className="HiringPhoto"
                        src={`/assets/images/equalHealthPhoto/${value.images}.jpg`}
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-12 mt--30">
                      <div className="hiring-section-title">
                        <h3>申請者入職要求：</h3>
                        <h4>{value.require}</h4>
                      </div>

                      <div className="hiring-section-title">
                        <h3>工作範圍：</h3>
                        <Container>
                          <Row>
                            {value.job &&
                              value.job.map((job, index) => (
                                <Col key={index + job} xs={6} sm={4}>
                                  <div className="MultiColHiringRequirement">
                                    <ul>
                                      <li>{job}</li>
                                    </ul>
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        </Container>
                      </div>
                    </div>
                    {/* End Single Service title */}
                    {/* • */}
                    {/* Start Single Service */}
                    {/* End Single Service */}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
