import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                      {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--24"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">同我哋傾吓</h2>
                                    <p>聯絡我們後，我們的工作人員將會在24小時內聯絡你以便了解你的情況，讓我們為你挑選最適合的護理人員。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                {/* big size  */}
                    <div className="LocationBig d-none d-md-block">
                        <a href="https://maps.app.goo.gl/feq7ArUwwuXB5jz4A">
                       <img
                        className="LocationImage-big"
                        src={`/assets/images/equalHealthPhoto/Location.jpg`}
                      />
                      </a>
                        </div>
                {/* small size  */}
                        <div className="LocationSmall d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <a href="https://maps.app.goo.gl/feq7ArUwwuXB5jz4A">
                       <img
                        className="LocationImage-small"
                        src={`/assets/images/equalHealthPhoto/Location_small.jpg`}
                      />
                      </a>
                        </div>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">電話聯絡</h4>
                                        <p><a href="tel:+852 61613218">+852 61613218</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">電郵：cs@equalhealthhk.com</h4>
                                        <p><a href="cs@equalhealthhk.com">cs@equalhealthhk.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">地址</h4>
                                        <a href="https://maps.app.goo.gl/feq7ArUwwuXB5jz4A">葵涌華星街1-7號美華工業大廈15樓9室 Room 9,15/F,<br/> Mai Wah Industrial Building,<br/> 1-7 Wah Sing Street,<br/> Kwai Chung, HK</a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area TODO form */}
                {/* <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div> */}
                {/* End Contact Page Area  */}


                



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />
                
            </React.Fragment>
        )
    }
}
export default Contact