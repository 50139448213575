import React, { Component , Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterTwo";
import Testimonial from "../elements/Testimonial";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { IoMdContacts } from "react-icons/io"
import { AiOutlineCar } from "react-icons/ai"
import { FaPills, FaWheelchair,FaWpforms , FaUserNurse} from "react-icons/fa"
const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: '貼心陪診服務',
        description: '專業陪診服務，照顧你的親人，提供全方位的照顧和支持',
        buttonText: '聯絡我們',
        buttonLink: '/ContactUs'
    }
]

const ServiceCover = [
    {
        icon: <FaWheelchair/>,
        title: '日常協助',
        description: '扶抱及推輪椅，協助外出和上落交通工具等。'
    },
    {
        icon: <AiOutlineCar/>,
        title: '往返交通安排',
        description: '協助安排或預約交通往返目的地。'
    },
    {
        icon: <FaPills/>,
        title: '藥物管理',
        description: '向醫生反映家屬信息，領取藥物及記下醫生信息並匯報覆診情況。'
    },
    
]
const ServiceListV2 = [
    {
        icon: <FaWpforms/>,
        title: '第一步',
        description: '填妥表格'
    },
    {
        icon: <IoMdContacts/>,
        title: '第二步',
        description: '工作人員會在24小時內聯絡你以確認細節'
    },
    {
        icon: <FaUserNurse/>,
        title: '第三步',
        description: '細節確認完成後，為你安排最合適的護理人員'
    },
]



class EscortServices extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return(
            <Fragment>
                <Helmet pageTitle="Companion" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}
                

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--29" data-black-overlay="6">
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--40 bg_color--1" id="service">
                    <div className="container">
                    <div  className=" ptb--30"><row><h2><span>陪診員會......</span></h2></row></div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="feature-area">

                                    <ol type="1">
                                        <li><h3 className="title mt--20 fontWeight500 lineheight--1-8">陪伴岀席活動</h3></li>
                                        <li><h3 className="title mt--20 fontWeight500 lineheight--1-8">陪伴到醫院、門診或診所覆診</h3></li>
                                        <li><h3 className="title mt--20 fontWeight500 lineheight--1-8">日常生活需扶抱或推輪椅</h3></li>
                                    </ol>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 
                                {/* Start intro Area  */}

                                <div className="service-area ptb--40 bg_color--1">
                        <div className="container">
                        <div  className=" ptb--30"><row><h2><span>服務範疇</span></h2></row></div>

                                                <div className="row service-one-wrapper">
                                                {ServiceCover.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                               </div>
                               
                                        </div>
                        </div>
                {/* End intro Area  */} 

                                {/* Start BookingProcedure Area */}
                                <div className="service-area ptb--40 bg_color--1">
                    <div className="container">
                    <div  className=" ptb--30"><row><h2><span>預約三部曲</span></h2></row></div>
                        <div className="row service-one-wrapper">
                            {ServiceListV2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}
               

                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default EscortServices;