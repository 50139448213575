import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import { FiChevronUp , FiCheck } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaWpforms , FaUserNurse} from "react-icons/fa"
import { IoMdContacts } from "react-icons/io"


const SlideList = [
    {
        textPosition: 'text-right',
        bgImage: 'bg_image--1',
        category: '',
        title: '身心守護',
        description: '我們的承諾 — 讓你得到貼心和專業的看護服務',
        buttonText: '聯絡我們',
        buttonLink: 'https://wa.me/85261613218?text=你好,我想查詢有關服務'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--2',
        category: '',
        title: '健康專業',
        description: '我們的專業 — 照料您的需求，醫療專業無間斷',
        buttonText: '聯絡我們',
        buttonLink: 'https://wa.me/85261613218?text=你好,我想查詢有關服務'
    },
    
]

const ServiceListOne = [
    {
        icon: <FaWpforms/>,
        title: '第一步',
        description: '填妥表格'
    },
    {
        icon: <IoMdContacts/>,
        title: '第二步',
        description: '工作人員會在24小時內聯絡你以確認細節'
    },
    {
        icon: <FaUserNurse/>,
        title: '第三步',
        description: '細節確認完成後，為你安排最合適的護理人員'
    },
]

const starndardService = [
    {
        image: '1',
        title: '上門護理方案',
        description: '提供上門護理，定期訪問，醫療監測和藥物管理，照顧您居家健康。',
        route:"OnsiteServices"
    },
    {
        image: '2',
        title: '陪診服務',
        description: '陪伴支持，安心舒適就醫，提供情緒支持。',
        route:"Companion"
    },
    {
        image: '3',
        title: '外聘替補護理員',
        description: '解決人手不足，提供合資格護理員，持續高品質護理照顧。',
        route:"CaregiverServices"
    },
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}<IoLogoWhatsapp size={20} /></a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start BookingProcedure Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service Title */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">專業護理服務</h2>
                                    <p>提供專業周到的護理和醫療服務，照顧方案個性化，確保您健康舒適。</p>

                                </div>
                            </div>
                            {/* End Single Service title */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="thumbnai">
                                                    <img src={`/assets/images/equalHealthPhoto/nurseAndClient${value.image}.jpg`} alt="Corporate Images"/>
                                                </div>
                                                <div className="coxntent">
                                                    <h3><a href={value.route}>{value.title}</a></h3>
                                                    <p>{value.description}</p>
                                                    <a className="btn-transparent rn-btn-dark" href={value.route}><span className="text">瀏覽詳情</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

                {/* Start Counterup blowwater Area */}
                {/* <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div> */}
                {/* End Counterup Area */}


             

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;