import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/FooterTwo";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class TermDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Terms and Details" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h1>條款及條件</h1>
                    <p className="subtitle">
                      以下條款及條件，適用於頤護專業護理服務有限公司(下稱頤護)各職級的獨立承包護理人員提供的服務
                    </p>
                    <h3><u>中介公司身份(介紹人)</u></h3>
                    <p>
                      頤護為客戶介紹合適的獨立承包護理人員,
                      並將根據目前的收費率收費。獨立承包護理人員可以自由接納或拒絕服務機會,
                      頤護會盡力為客戶找尋合適的人選，但並不保證一定能成功安排。
                    </p>
                    <h3><u>獨立承包護理人員 (自僱人士)</u></h3>
                    
                    <p>
                      頤護為客戶介紹合適的獨立承包護理人員,
                      並將根據目前的收費率收費。獨立承包護理人員可以自由接納或拒絕服務機會,
                      頤護會盡力為客戶找尋合適的人選，但並不保證一定能成功安排。
                    </p>
                    <ol type="i">
                      <li>
                        護理人員作為獨立承包護理人員(自僱人士)(下稱護理人員)
                        提供服務, 他們不是頤護的員工;
                      </li>
                      <li>
                        頤護對每名護理人員進行合理的背景審查。這些護理人員都是獨立的承包護理人員,
                        單獨為其行為和水平負責。
                      </li>
                      <li>
                        一切工作安排，以本公司最後核實為準，本公司保留最後決定權。嚴禁護理人員私下接單賺取報酬。如發現違規情況，本公司將保留一切法律追究權利。
                      </li>
                      <li>
                        護理人員不會享有《僱員補償條例》的法定保障。本公司建議，護理人員應考慮為工作購買責任保險。
                      </li>
                      <li>
                        護理人員須根據《強制性公積金計劃條例》自行參加強積金計劃並作出供款。
                      </li>
                      <li>護理人員須按《稅務條例》報稅。</li>
                      <li>
                        工作時須保持儀容整潔、穿著整潔之制服或客戶要求的服飾。
                      </li>
                    </ol>
                    <p>
                      頤護不會為護理人員的行為承擔任何責任,
                      或對護理人員所提供的服務負上任何法律責任。
                    </p>
                    <h3><u>酬金</u></h3>
                    <p>
                      <i>客戶 : </i>
                    </p>
                    <p>
                      繳費單通常於每月20號月發出(公眾假期除外)，並於繳費單上註明的期間繳付付款方式為：
                    </p>
                    <ol type="i">
                      <li>
                        銀行轉賬或FPS（請將銀行轉賬收據的副本發送給頤護）
                        <ol type="1">
                          <li>
                            銀行代碼：大新銀行有限公司
                            <ul>賬戶名稱：EQUAL HEALTH (HK) LIMITED</ul>
                            <ul>帳號：7810-180-12878</ul>
                          </li>
                          <li>FPS帳號: 107322711</li>
                        </ol>
                      </li>
                      <li>
                        郵寄劃線支票，支票抬頭為“EQUAL HEALTH (HK) LIMITED”
                        至：葵涌華星街1-7號美華工業大廈15樓9室
                      </li>
                    </ol>
                    <p>
                      假如本人未能在服務開始前繳付賬單上所有費用，本人同意：
                      <ol type="a">
                        <li>頤護可向本人徵收（並在本人在合約上有義務支付）按相關繳費單總金額計算的5%附加費，以及</li>
                        <li>為本人服務的護理人員可停止工作，直到本人全數支付所有繳費單欠款爲止。</li>
                        </ol>
                      
                    </p>
                    <p>
                      <i>獨立承包護理人員 :</i>
                    </p>
                    <p>
                      服務酬金按工作時數，於每月7號(公眾假期除外)前發放。如有任何疑問，可以向本公司職員聯絡。
                    </p>
                    <p>
                      本人必須受制於頤護的工作守則，不得私下與客戶有薪酬上的協定。如客戶在薪酬上有任何疑問及協定，本人必須主動與頤護聯絡。如發現違規情況，本公司將保留一切法律追究權利。
                    </p>
                    <p>
                      本人會按照工作確認通知所列出的時間提供服務，遲到或早退有可能會被扣除相關服務酬金，有需要請盡早通知頤護。如實際工作時間與工作確認通知有差別，本人必須盡快通知頤護。
                    </p>
                    <h3><u>直接/間接聘用</u></h3>
                    
                    <p>
                      如果客戶直接/間接聘請由頤護安排的護理人員擔用相同/類似職位（“直接聘用”），客戶需向頤護支付一次性的介紹費。客戶需要通知頤護有關其直接/間接聘用該護理人員的意向。本條款的通知義務的有效期為一年，由客戶使用頤護的護理人員最後一天起計。客戶如未通知頤護有關直接/間接聘用而導致頤護損失介紹費，客戶將有責任向頤護賠償介紹費，即相等於該護理人員直接聘用中獲得的一個月的平均收入。
                    </p>
                    <h3><u>取消及更改</u></h3>
                
                    <p>
                      頤護將盡可能滿足客戶更改要求，如須更改^或取消服務，請於服務時間開始前72小時通知頤護。如果不足72小時通知期，將會產生
                      <u>行政費用$250</u>。
                      ^更改包括但不限於以下情況：地點變更、時間變更、客戶導致的服務時間延遲、人員變更以及服務時間縮短。
                      頤護保留最終退款及決定權。本公司有權要求客戶出示病人之死亡證作退款用途。
                    </p>
                    <h3><u>惡劣天氣</u></h3>
           
                    <p>
                      如護理人員需於黑色暴雨警告生效、極端天氣警示生效或8號或以上颱風信號懸掛期間工作，本公司將有以下安排:
                    </p>
                    <ol type="A">
                      <li>
                        服務收費會調整至一般收費的2倍，此特別收費安排有效期為：
                        <ol type="i">
                          <li>黑色暴雨警告在服務期間生效</li>
                          <li>8號或以上颱風信號在服務期間生效</li>
                          <li>極端天氣警示在服務期間生效</li>
                        </ol>
                      </li>
                      <li>
                        如黑色暴雨警告、極端天氣警示或8號或以上颱風信號於工作期間懸掛，客戶須承擔特別交通費(按交通收據收費實報實銷)。
                      </li>
                      <li>
                        取消服務安排:
                        <ol type="i">
                          <li>
                            為照顧護理人員生命安全，頤護不建議提供服務。但實際情況將按照護理人員意願而作最後決定，而頤護並不會負責護理人員的安全，客戶亦須承擔其特別交通費(按交通收據收費實報實銷)。
                          </li>
                          <li>
                            如需取消服務，請在服務開始前最少4小時通知頤護。否則將未能安排退款。
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <h3><u>終止協議</u></h3>
           
                    <p>
                      護理人員或頤護可以書面通知對方立即終止服務協議而無需解釋原因，如果護理人員正接受一項未完成的服務，服務協議終止的日期應自動推遲到最後服務日期，或護理人員與頤護達成協議的較早日期。
                    </p>
                    <p>
                      如護理人員從未於頤護接受任何工作介紹或已經沒有使用本公司的介紹服務達一年(以接受工作轉介的最後一日起)，護理人員於頤護的登記將為自動失效並由頤護銷毀。如護理人員再需要頤護轉介工作，須再次重新申請。
                    </p>
                    <h3><u>法律轄權</u></h3>
           
                    <p>
                      服務協議及本文件受香港法律管轄及按照香港法律解釋，協議雙方願受香港法院的非專屬司法管轄權。
                    </p>
                    <h3><u>個人資料收集聲明</u></h3>

                    <p>
                      本人同意，頤護收集客戶的個人資料並由頤護使用作服務介紹及其他相關用途，包括但不限於評估本人的需求（及患者）以及為本人安排的護理人員。本人的個人資料將直接存儲在頤護的資料庫中，只能由頤護獲授權員工查閱，他們將查閱本人的個人資料，以便為本人提供合適介紹。
                    </p>
                    頤護可向下列人士披露本人的個人資料或其任何部分披露給：
                    <ol type="a">
                        <li>任何表示有興趣為本人提供服務的護理人員;</li>
                        <li>頤護為上述任何目的或與之相關的任何目的聘用的任何承包人士，代理人，其他人或團體，或向頤護提供行政管理，電訊，電腦或其他服務的任何第三方服務供應商;</li>
                        <li>根據適用的法律，政府或監管要求頤護作出披露的任何人士;及</li>
                        <li>為頤護履行上述目的而合理要求作出披露的其他人士。</li>
                    </ol>
                    <p>本人明白，頤護可保留本人的個人資料直到在收到本人的書面通知後6個月為止或法律規定的期限為止。根據“個人資料（私隱）條例”（香港法例第486章），本人有權要求查閱和/或更正本人在頤護登記的個人資料。此等要求須以書面作出，郵寄至：EQUAL HEALTH (HK) LIMITED葵涌華星街1-7號美華工業大廈15樓9室</p>
                    <p>本人同意頤護所有權就任何處理查閱資料的要求收取合理費用。</p>
                    <p>頤護保留利用協議上提到之服務範圍以作出促銷和營銷目的權利，包括用於頤護網站，社交媒體和其他媒體渠道。客戶並同時授予頤護使用客戶名稱，徽標和商標以作此類促銷和營銷活動的權利和許可 。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default TermDetails;
